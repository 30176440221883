<template>
    <div class="home">
      <div class="pt20"></div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item style="font-weight: bold;" label="头像名称">
              <el-input v-model="formInline.query" placeholder="名称"></el-input>
            </el-form-item>
            <el-form-item label="头像状态">
              <el-select v-model="formInline.type" placeholder="请选择头像状态">
                <el-option label="全部" :value="0"></el-option>
                <el-option label="上架" :value="1"></el-option>
                <el-option label="下架" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
      </el-form> 
      <div class="left">
        <el-button v-if="add == 1" @click="goNav('/headCategory/headShopadd')" type="primary" style="font-size: 16px;font-weight: bold;" plain>添加头像</el-button>
      </div>

      <div class="pt20"></div>
      <el-table
      v-loading="loading"
        :data="tableData"
        style="width: 100%">
       <el-table-column label="序号" :index="typeIndex" type="index" show-overflow-tooltip width="100">
        </el-table-column>
        <el-table-column
          prop="head_img"
          label="头像">
          <template slot-scope="scope">
            <el-image
            v-if="scope.row.head_img"
            style="width: 88px; height: 88px"
            :src="scope.row.head_img"></el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="use_num"
          label="图像使用人数">
        </el-table-column>
        <el-table-column
          prop="remark"
          label="中文简介">
        </el-table-column>
        <el-table-column
          prop="en_remark"
          label="	英文简介">
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态">
          <template slot-scope="scope">
            {{scope.row.status == 1 ? '上架' : ''}}
            {{scope.row.status == 2 ? '下架' : ''}}
          </template>
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
        <el-button v-if="edit == 1" @click="goedit(scope.row)" size="mini" type="info" plain>编辑</el-button>
        <el-button v-if="detail == 1" @click="headstatus(scope.row)" size="mini" type="info" plain>{{scope.row.status == 1 ? '下架' : '上架'}}</el-button>
        <el-button v-if="detail == 1" @click="delhead(scope.row)" size="mini" type="info" plain>删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pt20"></div>
        
<el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="current_page"
      :page-size="per_page"
      layout="total,prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'

export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      loading:true,
      add:location.add,
      dele:location.delete,
      edit:location.edit,
      detail:location.detail,
      total:0,
      per_page: 0,
      current_page:1,
      tableData: [],
      formInline: {
        query:'',
        type:0,
        page:1,
      },
      initdata:{
        query:'',
        type:0,
        page:1,
      },
      timevalue:'',
      // 选中数组
      ghs: [],
     //选中的记录数量
     selectedNum:0,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  methods: {
      onSubmit(CHA){
        let _this = this;
        _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
        axios.get(config.headShop,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
           _this.$message.error(error);
        });
      },
    setpage(page){
      this.total = page.total;
      this.per_page = page.per_page;
      this.current_page = page.current_page;
    },typeIndex(index) {
      let _this = this; //处理分页数据的 index
      return (_this.formInline.page - 1) * 20 + index + 1;
    },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
      goNav(url){
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
        this.$router.push(url);
      },
      goedit(obj){
        this.$router.push({ name: 'headShopedit', params: { obj:obj } });
      },
      headstatus(obj){
        let _this = this;
        let status = obj.status == 1 ? 2 : 1;
        axios.post(config.headStatusChange,{id:obj.id,status})
        .then(response => {
          if(response.data.code == 200){
            _this.$message({
              message: response.data.message,
              type: 'success'
            });
            _this.onSubmit();
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
      },
      delhead(obj){
        let _this = this;
        axios.post(config.headStatusChange,{id:obj.id,status:3})
        .then(response => {
          if(response.data.code == 200){
            _this.$message({
              message: response.data.message,
              type: 'success'
            });
            _this.onSubmit();
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
      },
      goedit2(obj){
        obj.look = true;
        sessionStorage.setItem("edit", JSON.stringify(obj));
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
      //  this.$router.push('/frontpage/frontpagelabeledit');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/frontpage/frontpagelabeledit');
      },
    init(){
        let _this = this;
        let a = sessionStorage.getItem('formInline');
        this.formInline = a?JSON.parse(a):this.initdata;
        axios.get(config.headShop,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 335px;
    text-align: left;
}
</style>